<template>
  <div>
    <b-container fluid>
      <button-back />
      <b-row>
        <b-col cols="12">
          <h3>Tipos de Tours</h3>
          <ButtonTutotial :module="module" />
        </b-col>
      </b-row>
      <b-card no-body class="card-global overflow-hidden">
        <base-action-table
          :records="records"
          :fields="fields"
          :busy="isBusy"
          :filtering="filtering"
          :module="module"
          @creating="handleCreating"
          @detail="handleDetail"
          @editing="handleEditing"
          @deleting="handleDeleting"
        >
        </base-action-table>
      </b-card>
    </b-container>

    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-plan-client :record="record" :isDisable="isDisable" @save="save" />
      </template>
    </base-modal>
  </div>
</template>

<script>
import { BContainer, BCol, BRow, BCard, } from "bootstrap-vue";
import ActionCRUD from "@/mixins/ActionCRUD";
import BaseActionTable from "@/components/base/BaseActionTable";
import BaseModal from "@/components/base/BaseModal";
import FormPlanClient from "./FormPlanClient";
import ButtonBack from "@/components/buttons/ButtonBack";
import ButtonTutotial from "@/components/buttons/ButtonTutotial";
export default {
  inject: ["planClientRepository"],
  mixins: [ActionCRUD],
  components: {
    BContainer,
    BCol,
    BRow,
    BCard,
    BaseActionTable,
    BaseModal,
    FormPlanClient,
    ButtonBack,
    ButtonTutotial,
  },
  data() {
    return {
      records: [],
      record: null,
      fields: [
        {
          key: "name",
          label: "Nombre",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: true,
      isDisable: false,
      title: "Crear tipo tour",
      id: "plan-client-modal",
      module: "bb417b49-8a91-4d73-b5b3-bfc076296663",
      repository: "planClientRepository",
    };
  },
  async mounted() {
    const me = this;
    await me.handleLoadData();
  },
  methods: {
    handleCreating() {
      const me = this;
      me.title = "Crear tipo tour";
      me.isDisable = false;

      me.record = null;
      me.$bvModal.show(me.id);
    },
    handleDetail(item) {
      const me = this;
      me.title = "Detalle tipo tour";
      me.isDisable = true;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar tipo tour";
      me.isDisable = false;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    async handleDeleting(id) {
      await this.del(id);
    },
  },
};
</script>

<style></style>
