<template>
  <h6>
    <router-link :to="{ name: 'tutorial', params: { uuid: module }}">
      <b-icon-play-btn-fill /> Aprende aqui.
    </router-link>
  </h6>
</template>

<script>
import { BIconPlayBtnFill } from "bootstrap-vue";
  export default {
    props: {
      module: {
        type: String,
        default: '',
      }
    },
    components: {
      BIconPlayBtnFill,
    }
  }
</script>

<style lang="css" scoped>

</style>